<template>

    <div class="relative w-full h-auto overflow-auto bg-white">
        <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0 css-144ctnr">
            <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
                <div>
                    <div class=""><span class="css-79e8z3">Safe Payments</span></div>
                    <h1
                        class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl xl:text-6xl css-1wfjlp">
                        Payment Protection with Escrow</h1>
                    <div class=" xl:mt-1">
                        <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                            style="font-size: 20px; line-height: 1.3; font-family: inherit;">
                            Escrow is Simple, safe and secure - Qapin is the hassle-free way to get paid.</p>
                    </div>
                    <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex"><button @click="$router.push('/client/dashboard').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                            Post Job </button><br>
                        <button @click="$router.push('/freelancer').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                            Find Job </button>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/a.svg" alt="" class="object-contain h-96 w-100"  />
                </div>
            </div>
        </div>
        <!-- <TheHomeSiteHeader /> -->

        <div class="w-full pt-5  mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div>
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px; ">
                        What is Escrow?
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            Keeping funds in an escrow account can help protect clients and Talents throughout job process.
                            Before talent start the work, the money is held in Escrow and once the client confirms they are
                            satisfied, it is released immediately</p>

                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            An escrow account means that the <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> won’t receive payment until clients are completely
                            satisfied with the work. Once clients are happy to release payment it’s just a matter of
                            pressing a couple of buttons to release the payments.</p>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/safe-pay/Payment-2.png" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
                </div>
            </div>
        </div>

        <div class="w-full pt-5  mb-20 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div class="mobile-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px;">
                        How Does Escrow Work?
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">Freelancer </a> and clients will mutually discuss and agree for minimum milestones and amount for
                            each milestone.
                        </p>

                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            Client funds first milestone according to the Payment terms in the Agreement before sending
                            offer to Talents.
                        </p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            After accepting the offer, <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">Freelancer</a> complete the work according to the expectations set in the
                            Contract.
                        </p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            Clients can use the funds in escrow to release after reviewing the submitted work.
                        </p>
                    </div>

                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/safe-pay/Payment-1.jpg" alt=""  class="about-page-img" style="width: 100%; height: 500px;"/>
                </div>
                <div class="desktop-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi "
                        style=" max-width: 40rem; padding-top: 80px;">
                        How Does Escrow Work?
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">Freelancer</a> and clients will mutually discuss and agree for minimum milestones and amount for
                            each milestone.
                        </p>

                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            Client funds first milestone according to the Payment terms in the Agreement before sending
                            offer to Talents.
                        </p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            After accepting the offer, <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">Freelancer</a> complete the work according to the expectations set in the
                            Contract.
                        </p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko option-check">
                            <i class="fa fa-check"></i>
                            Clients can use the funds in escrow to release after reviewing the submitted work.
                        </p>
                    </div>

                </div>

            </div>
        </div>

        <!-- ======= Frequently Asked Questions Section ======= -->
        <div id="faq" class="faq section-bg pt-5  pb-5 css-144ctnr" >
            
            <div class="container">

                <div class="section-title">
                    <div class="w-full px-4 mx-auto mt-7 xl:px-0 max-w-7xl">
                        <h2 class="flex justify-center text-3xl text-center text-black fw-bold xl:text-6xl">
                        Frequently asked Questions
                        </h2>
                    </div>
                </div>

                <div class="faq-list">
                    <ul class="pay-safe-ui">
                        <li>
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse" class="collapse"
                                data-bs-target="#faq-list-1">Is it mandatory to use Escrow? <i
                                    class="fa fa-chevron-down icon-show"></i><i class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                <p>
                                    Yes, for the first milestone it is mandatory to fund while sending the offer and second milestone onwards it’s not mandatory however we recommend using escrow especially when you are working with a talent or clients for the first time.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="100">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-2" class="collapsed">What Would happen for the funds if <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> did not accept the offer
                                <i class="fa fa-chevron-down icon-show"></i><i class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    In the event of <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> reject the offer you sent; the funds will return to you. Refunds can only be sent back to the original payment method used to fund the milestone. we recommend confirming availability and payments terms though in-app chat system before sending offer to talents.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="200">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-3" class="collapsed">How refund from escrow if I don’t satisfy with my <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer’s</a> work
                                <i class="fa fa-chevron-down icon-show"></i><i class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    If you are not satisfied with your <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> work, discuss for re-work and resubmission of work however if you are not satisfied for the work delivered, the job can be ended without releasing the payments. There will be option to release “full payment” or “partial” or “None”. According to your work satisfaction chose the option. If you release partial or none, escrow return request will be sent to <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a>, and the <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> is able accept or reject the escrow refund request and chose for Qapin’s Arbitration services in the event of rejection.
                                </p>
                            </div>
                        </li>

                        <li data-aos-delay="300">
                            <i><img src="web/logo/favicon.svg" alt="" class="icon-help"></i> <a data-bs-toggle="collapse"
                                data-bs-target="#faq-list-4" class="collapsed">What is Arbitration while using Escrow? <i class="fa fa-chevron-down icon-show"></i><i
                                    class="fa fa-chevron-up icon-close"></i></a>
                            <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                                <p>
                                    We offer Arbitration services only for funds held in Escrow. Either party can request for Arbitration. Once a decision is reached, funds in escrow are distributed.
                                    For each Arbitration, Qapin will charge 10% of escrow amount or max 30USD per Arbitration.
                                </p>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>
        </div><!-- End Frequently Asked Questions Section -->

        <div class="xl:px-0">
            <div class="inset-0 xl:px-0">
                <div class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto"
                    style="background:  rgb(242 97 60);">
                    <div class="relative footer-vectors">

                    </div>

                    <div class="py-6 text-center xl:py-14">
                        <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>

                        <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
                            Register for Qapin today
                        </h4>

                        <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto"
                            style="max-width: 60rem;">
                            it’s the future of inspection services. Bringing the inspection community
                            together in one easy to use platform, Qapin is simple, free and convenient
                            but don’t take our word for it - create your
                        </p>

                        <div class="px-10 mt-6 xl:px-0">
                            <button @click="$router.push('/login').catch(()=>{})"
                                class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                                Join for Free
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <TheHomeSiteFooter class="" v-if="!isAuth" /> -->
    </div>
</template>

<script>
// import TheHomeSiteHeader from "../../TheHomeSiteHeader.vue";
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";
import "tiny-slider/src/tiny-slider.scss";
export default {
    components: {
        // TheHomeSiteFooter,
        //  TheHomeSiteHeader
    },
    data() {
        return {
            isAuth: null,
        }
    },
    mounted() {
        this.isAuth = localStorage.getItem("token");
        if (this.$route.name == "ExpireLink") {
            this.isAuth = null;
        }
        this.getCurrentUserDetails();
    },
}
</script>
<style scoped>
.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}
.faq.css-144ctnr {
    padding: 20px!important;
}
.option-check i {
    margin-right: 15px;
    color: chocolate;
}

.css-79e8z3 {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(13, 32, 73);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 170%;
    background-color: rgb(191 153 153 / 20%);
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.css-12k8mko {
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    max-width: 600px;
    display: flex;
    align-items: baseline;
}

.css-1plhbgk {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(28, 28, 28);
    font-family: Silka, Helvetica, sans-serif !important;
    line-height: 170% !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 64px !important;
}

.css-ajm3pi {
    margin: 0px 0px 24px;
    letter-spacing: 0.00735em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 43px !important;
    font-weight: 700 !important;
    max-width: 600px !important;
    font-size: 28px !important;
}

.hero-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
}

.margin-15 {
    font-size: 19px;
}

.agenct-scale {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    margin-bottom: 16px;
    margin-top: 0;
    text-decoration: none;
}

@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}

.css-144ctnr {
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  -moz-column-gap: 64px;
  column-gap: 64px;
  padding: 113px 43px;
  min-height: 567px;
  background: rgba(242, 97, 60, 0.07);
}

.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px !important;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}

@media (min-width: 1280px) {
    .xl\:text-6xl {
        font-size: 4.75rem;
        line-height: 1.2;
    }

    .xl\:pt-40 {
        padding-top: 12rem;
    }
    .xl\:max-w-7xl {
    max-width: 85rem;
}
}

.faq .faq-list {
    padding: 0 100px;
}

.faq .faq-list ul {
    padding: 0;
    list-style: none;
}

.faq .faq-list li+li {
    margin-top: 15px;
}

.faq .faq-list li {
    padding: 20px;
    background: #fff !important;
    border: 1px solid #e0dede;
    border-radius: 4px;
    position: relative;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
    color: #f2613c !important;
}

.faq .faq-list .icon-help {
    margin-top: 3px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    left: 20px;
}


.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .faq-list p {
    margin-bottom: 0 !important;
    padding: 10px 0 0 0;
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    display: flex;
    align-items: baseline;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list a.collapsed {
    color: #343a40 !important;
}

.faq .faq-list a.collapsed:hover {
    color: #5c9f24;
}

.faq .faq-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .faq-list {
        padding: 0;
    }
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5c9f24;
}

.section-title p {
    margin-bottom: 0;
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px !important;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
</style>
