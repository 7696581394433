import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import post from "../store/post";
import global from "../store/global";
import settings from "../store/settings";
import createPersistedState from "vuex-persistedstate";
// const canceltoken = axios.CancelToken.source();
// stores modules
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    projectName               : 'Qapin',
    isNewsfeed                : true,
    lastpostId                : 0,
    headToken                 : '',
    listAccount               : {},
    profileImg                : '',
    accountType               : localStorage.getItem("accountType"),
    currentUserData           : '',
    loginUserUuid             : '',
    currentUserOwnerData      : '',
    baseUrl                   : 'https://api.qapin.com/api/',
    baseUrlImg                : 'https://api.qapin.com/',
    frontendUrl               : 'https://qapin.com/',
    domain                    : 'https://blog.qapin.com',
    swiftKey                  : 'sk_ba60879438928fe650e11e4f3614448a340d8e8875a34cc15f4e05c140b9afac',
    LanguageData              : [],
    talentData                : [],
    ExperienceLevelData       : [],
    certificationList         : [],
    tempData                  : {},
    enterBtnRole              : 'B',
    currentUrl                : '',
    stopBodyScroll            : false,
    InvoiceFromDetails        : {
      company_name              : 'Qapin Global Inc.',
      address                   : '9655 Granite Ridge Dr Suite 200',
      zipcode                   : 'CA 92123',
      country                   : 'United States',
      city                      : 'San Diego',
      GSTIN                     : '',
    },
    singleInvoice             : '',
    mobileWindow              : false,
    userOnlineStatus          : 0,
    userTimeZone              : '',
    serverTimeZone            : 'Africa/Accra',
  },
  mutations: {
    setUserOnlineStatus(state, payload) {
      state.userOnlineStatus = payload;
    },
    setcurrentUserData(state, payload) {
      state.currentUserData = payload;
    },
    setcurrentUserLoginUuid(state, payload){
      state.loginUserUuid  = payload;
    },
    setcurrentUserOwnerData(state, payload) {
      state.currentUserOwnerData = payload;
    },
    isNewsfeed(state, payload) {
      state.isNewsfeed = payload;
    },
    lastpostId(state, payload) {
      state.lastpostId = payload;
    },
    CLEARERROR(state) {
      state
    },
    setListOfAccount(state, payload) {
      state.listAccount = payload;
    },
    setProfileImg(state, payload){
      state.profileImg = payload;
    },
    setaccountType(state, payload){
      state.accountType = payload;
    },
    setEnterBtnRole(state, payload){
      state.enterBtnRole = payload;
    },
    GET_LANGUAGE_LIST(state, data) {
      state.LanguageData = data;
    },
    GET_TALENT_LIST(state, data) {
      state.talentData = data;
    },
    GET_EXPERIENCE_LEVEL(state, data) {
      state.ExperienceLevelData = data;
    },
    GET_CERTIFICTION_LIST(state, data){
      state.certificationList = data;
    },
    SET_TEMPDATA(state,data){
      state.tempData = data;
    },
    Update_Current_Url(state, data) {
      state.currentUrl = data;
    },
    Update_Body_Scroll(state, data) {
      state.stopBodyScroll = data;
    },
    update_singleinvoice(state,data){
      state.singleInvoice = data;
    },
    update_mobileWindow(state,data){
      state.mobileWindow = data;
    },
    setCurrentTimeZone(state,data){
      state.userTimeZone = data;
    }
  },
  actions: {
    currentTimeZone(context, value) {
      context.commit("setCurrentTimeZone", value);
    },
    UpdateUserOnlineOfflineStatus({commit},{params}){
      commit('setUserOnlineStatus',params);
      return true;
    },
    updateWindowStatus({commit},{params}){
      commit('update_mobileWindow',params)
    },
    updateSingleInvoiceData(context, value){
      context.commit('update_singleinvoice',value)
    },
    // this fun is for to update scroll property
    UpdateBodyScrollRole({commit},{params}){
      commit('Update_Body_Scroll',params)
    },
    // this fun is for to update chat Room data request
    UpdateCurrentUrl({ commit },{ params }) {
      commit('Update_Current_Url', params);
    },
    TempDataUpdate({commit},value) {
      commit("SET_TEMPDATA", value);
    },
    async genericAPIPOSTRequest({ commit }, { requestUrl, params }) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      try {
        let res;
        if (params) { 
          res = await axios.post(this.state.baseUrl + requestUrl, params,{"headers":this.headToken});
        } else {
          res = await axios.post(this.state.baseUrl + requestUrl);
        }
         
        if(res.data.status){
          commit("CLEARERROR");
          return res.data.result; 
        }else{
          //this.$toastr.e(res.data.message);
          return res.data.result; 
        }
      } catch (error) {
        // return false;
        if(error=='Error: Request failed with status code 401'){

            this.state.currentUserData = '';
            localStorage.removeItem("token"); 
            localStorage.removeItem("accountType"); 
            localStorage.removeItem("loginUserData"); 
            // this.$router.push("/login").catch(()=>{});
            window.location.href = "/login";
        }
        //this.$toastr.e('Something went wrong please try again!');
        // const nerror = wrapError(error)
        // commit('SETERROR', nerror)
        // throw nerror
      } finally {
        //this.$toastr.e('Something went wrong please try again!');
        //commit('CHANGELOADINGSTATE', { isprocessing: false });
      }
    },
    async fetchApis({ commit },{requestUrl,params}) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +requestUrl,params,{"headers":this.headToken})
          .then((response) => {
            commit("CLEARERROR");
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async cancelApiRequest({ commit }) {
      // this.state.canceltoken.cancel();
      // this.state.canceltoken=null;
      commit("CLEARERROR");

    },    
    async fetchLanguageList({ commit }) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +'getLanguageList','',{"headers":this.headToken})
          .then((response) => {
            commit('GET_LANGUAGE_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchCompanyAccountList({ commit },{params}) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +'company/getCompanyCount',params,{"headers":this.headToken})
          .then((response) => {
            // commit('GET_LANGUAGE_LIST', response.data.result)
            commit("setListOfAccount", response.data.result);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchTalentList({ commit },{params}) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +'getTalentList',params,{"headers":this.headToken})
          .then((response) => {
            commit('GET_TALENT_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    listAccount(context, value) {
      context.commit("setListOfAccount", value);
    },
    profileImg(context, value) {
      context.commit("setProfileImg", value);
    },
    currentUserData(context, value) {
      context.commit("setcurrentUserData", value);
    },
    currentUserLoginUuid(context, value) {
      context.commit("setcurrentUserLoginUuid", value);
    },
    accountType(context, value) {
      context.commit("setaccountType", value);
    },
    UpdateEnterBtnRole(context, value) {
      context.commit("setEnterBtnRole", value);
    },
    async fetchExperienceLevelList({ commit }) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +'getExperienceLevel','',{"headers":this.headToken})
          .then((response) => {
            commit('GET_EXPERIENCE_LEVEL', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },    
    async fetchCertificationList({ commit }) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +'user/getCertification','',{"headers":this.headToken})
          .then((response) => {
            commit('GET_CERTIFICTION_LIST', response.data.result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchInvitadeUserPermissionList({ commit }) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios.post(this.state.baseUrl +'getUserPermission',{'user_id':this.state.currentUserData.user_id,'invited_team_id':this.state.currentUserData.invited_team_id},{"headers":this.headToken})
          .then((response) => {
            var data = this.state.currentUserData
            data.permission = response.data.result;
            commit('setcurrentUserData', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
        
  },
  
  getters: {
    getInvoiceFromDetails(state) {
      return state.InvoiceFromDetails;
    },
    getUserOnlinestatus(state) {
      return state.userOnlineStatus;
    },
    getLanguageListdata(state) {
      return state.LanguageData;
    },
    getTalentListdata(state) {
      return state.talentData;
    },
    getExperienceLevelListdata(state) {
      return state.ExperienceLevelData;
    },
    getCertificationListdata(state){
      return state.certificationList;
    },
    getTempData(state){
      return state.tempData;
    },
    getUserData(state){
      return state.currentUserData;
    },
    getcurrentUrl: (state) => state.currentUrl,
    getenterBtnRole: (state) => state.enterBtnRole,
    getstopBodyScroll:(state) => state.stopBodyScroll,
    getmobileWindow :(state)=> state.mobileWindow,
  },
  modules: {
    post,
    global,
    settings,
  },
});
