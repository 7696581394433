<template>
    <div>
        <section class="enterprise-banner-section-wrapper">
            <b-container>
                <b-row>
                    <b-col offset-xl="1" xl="5" offset-lg="1" lg="6" sm="12">
                        <h5 class="home-small-title text-white mt-lg-0 mt-4 mb-0 fz-18">For Enterprise</h5>
                        <h1 class="hero-2-title text-white mb-lg-4 mb-0">Built with enterprise in mind</h1>
                        <p class="mb-4 text-white">Grow your international team fast without setting up entities overseas. We take care of all of the compliance, payroll, taxes and HR admin.</p>
                        <a href="#" class="btn btn-outline-theme">Request a Demo</a>
                    </b-col>
                    <b-col xl="5" lg="5" sm="12">
                        <div class="image-holder">
                            <img src="" class="img-fluid">
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    
        <section class="enterprise-aboout-section-wrapper">
            <b-container>
                <b-row>
                    <b-col xl="12" lg="2" sm="12" class="text-center">
                        <h2 class="hero-2-title mb-0 fw-600">Qapin allows enterprises to stay flexible and hire <br> globally in minutes</h2>
                        <p class="mb-4">Grow your international team fast without setting up entities overseas. <br> We take care of all of the compliance, payroll, taxes and HR admin.</p>
                        <a href="#" class="btn btn-theme">Request a Demo</a>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col offset-xl="1" xl="5" offset-lg="1" lg="5" sm="12">
                        <h3 class="hero-2-title mb-lg-4 mb-0">Enter new markets without opening an entity</h3>
                        <p class="mb-4">Expand and hire employees in new markets fast, without the cost and ongoing admin of opening an entity.</p>
                        <ul>
                            <li>
                                <p style="font-size: 16px;">We own entities in 50 countries (and counting) and manage the entire employment process</p>
                            </li>
                            <li>
                                <p style="font-size: 16px;">Qapin takes on all employment liability and makes sure you’re compliant with local laws</p>
                            </li>
                            <li>
                                <p style="font-size: 16px;">Taxes, social security, termination requirements, benefits, everything is handled</p>
                            </li>
                        </ul>
                    </b-col>
                    <b-col xl="5" lg="5" sm="12">
                        <div class="image-holder">
                            <img src="" class="img-fluid">
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col offset-xl="1" xl="5" offset-lg="1" lg="5" sm="12">
                        <div class="image-holder">
                            <img src="" class="img-fluid">
                        </div>
                    </b-col>
                    <b-col xl="5" lg="5" sm="12">
                        <h3 class="hero-2-title mb-lg-4 mb-0">Support for all of your hiring needs</h3>
                        <p class="mb-4">Whether you want to <a href="javascript:void(0)" @click="$router.push({name:'SafePayments',path:'/safe-payments'}).catch(()=>{})">hire contractors</a> in Germany or an employee in Brazil, you can hire great talent anywhere, however you like.</p>
                        <ul>
                            <li>
                                <p style="font-size: 16px;">Onboard talent in 150 countries in minutes in compliance with the local laws</p>
                            </li>
                            <li>
                                <p style="font-size: 16px;">Manage all employee types: employees, and contractors and payroll</p>
                            </li>
                            <li>
                                <p style="font-size: 16px;">From one new team member to 10,000, we’re ready to scale with you</p>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col offset-xl="1" xl="5" offset-lg="1" lg="5" sm="12">
                        <h3 class="hero-2-title mb-lg-4 mb-0">Fast-track any mergers and acquisitions</h3>
                        <p class="mb-4">Getting new team members onboard from an acquisition can take forever if they live in different countries. <b>With Qapin, it takes minutes (literally)</b>.</p>
                        <ul>
                            <li>
                                <p style="font-size: 16px;">There’s no need to set up local payroll or open entities around the globe, we handle it all</p>
                            </li>
                            <li>
                                <p style="font-size: 16px;">Bulk onboard team members from 150 countries with easy self-onboarding</p>
                            </li>
                            <li>
                                <p style="font-size: 16px;"><b>Fun fact:</b> we used Qapin to onboard staff from our own acquisition in one day</p>
                            </li>
                        </ul>
                    </b-col>
                    <b-col xl="5" lg="5" sm="12">
                        <div class="image-holder">
                            <img src="" class="img-fluid">
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col offset-xl="1" xl="5" offset-lg="1" lg="5" sm="12">
                        <div class="image-holder">
                            <img src="" class="img-fluid">
                        </div>
                    </b-col>
                    <b-col xl="5" lg="5" sm="12">
                        <h3 class="hero-2-title mb-lg-4 mb-0">Top-notch enterprise-grade data protection</h3>
                        <p class="mb-4">Qapin is ready to meet your business's compliance and regulatory requirements.</p>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="enterprise-aboout-section-wrapper">
            <b-container>
                <b-row>
                    <b-col offset-xl="1" xl="11" lg="11" sm="12">
                        <b-row>
                            <b-col xl="4" lg="4" sm="12">
                                <div class="content">
                                    <ul>
                                        <li style="font-size: 16px;">Authenticate access to your Qapin account with Single Sign On</li>
                                    </ul>
                                </div>
                            </b-col>
                            <b-col xl="4" lg="4" sm="12">
                                <div class="content">
                                    <ul>
                                        <li style="font-size: 16px;">We’re compliant with GDPR and privacy laws around the world</li>
                                    </ul>
                                </div>
                            </b-col>
                            <b-col xl="4" lg="4" sm="12">
                                <div class="content">
                                    <ul>
                                        <li style="font-size: 16px;">We take on employment liability to make sure you’re compliant globally</li>
                                    </ul>
                                </div>
                            </b-col>
                            <b-col xl="4" lg="4" sm="12">
                                <div class="content">
                                    <ul>
                                        <li style="font-size: 16px;">Components of the Qapin platform are SOC 2 Type 2 compliant</li>
                                    </ul>
                                </div>
                            </b-col>
                            <b-col xl="4" lg="4" sm="12">
                                <div class="content">
                                    <ul>
                                        <li style="font-size: 16px;">Our entire information security program is built on the ISO 27001 framework</li>
                                    </ul>
                                </div>
                            </b-col>
                            <b-col xl="4" lg="4" sm="12">
                                <div class="content">
                                    <ul>
                                        <li style="font-size: 16px;">Manage user permissions with ease with complete flexibility for teams</li>
                                    </ul>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="enterprise-request-section-wrapper">
            <b-container>
                <b-row>
                    <b-col xl="12" lg="2" sm="12" class="text-center">
                        <h2 class="hero-2-title mb-3 fw-600 text-white">Qapin makes growing remote and international teams effortless. <br> Ready to get started?</h2>
                        <a href="#" class="btn btn-outline-theme mb-4">Request a Demo</a>
                        
                    </b-col>
                </b-row>
            </b-container>  
        </section>

    </div>
</template>
<script type="module">
    export default {
        components: {},

        data() {
            return {  
                
            };
        },
        mounted() {
            
        },
    };
</script>