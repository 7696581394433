<template>
  <!-- web menu bar -->
  <div class="flex items-center justify-end w-full transition-all duration-500 ease-in-out sm:mt-0" v-if="isAuth">
    <!-- Toggle Mobile Navigation -->
    <div class="block sm:block lg:hidden md:block xl:hidden">
      <div class="flex justify-end">
        <MenuAlt1Icon v-b-toggle.sidebar-no-header @click="accountToggle = false"
          class="w-12 h-12 p-2 text-gray-300 transition-all ease-in rounded-full cursor-pointer duration-600 hover:text-primary-500 hover:bg-gray-100" />
      </div>
    </div>

    <div class="transition-all duration-500 ease-in-out">
      <div class="flex items-center" style="padding: 0 0 7px">
        <!--  Desktop Menu Items  -->
        <div
          class="hidden w-full pr-0 transition-all ease-in-out menu-items-list duration-600 xl:mr-10 lg:block md:hidden xl:block">

          <div class="">
            <ul class="flex items-center h-10 pb-0 mt-3 mb-2 space-x-4 text-xs font-semibold">
              <!-- this is freelancer menu bar -->
              <template v-if="$store.state.accountType == 'freelancer'">
                <!-- Find Work Container -->
                <AppDropdown class="flex items-center justify-end h-full p-3" >
                  <template slot="toggler">
                    <div
                      class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      <a href="javascript:void(0);" class="text-muted text-decoration-none">Find Work</a>
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/freelancer/search').catch(()=>{})" class="menu">Find Jobs</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/saved-jobs').catch(()=>{})">Saved Jobs</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu"
                        @click="$router.push('/freelancer/my-proposals').catch(()=>{})">Proposals</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/my-profile').catch(()=>{})" class="menu">Profile</a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>

                <!-- My Job Container -->
                <AppDropdown class="flex items-center justify-end h-full p-3">
                  <template slot="toggler">
                    <div 
                      class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      My Jobs
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/freelancer/my-jobs').catch(()=>{})" class="menu">My Jobs</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/freelancer/all-contracts').catch(()=>{})">All
                        Contracts</a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>

                <!-- <li @click="$router.push('/freelancer/search')" class="p-3 transition-all duration-300 ease-in rounded-md cursor-pointer fz-16 fw-600 text-muted">
                  Reports
                </li> -->

                <AppDropdown class="flex items-center justify-end h-full p-3">
                  <template slot="toggler">
                    <div 
                      class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      Reports
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/freelancer/over-view').catch(()=>{})" class="menu">OverView</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/freelancer/trasaction-history').catch(()=>{})">Transaction History</a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>
              </template>
              <!-- this is agency menu bar -->
              <template v-if="$store.state.accountType == 'agency'">
                <!-- Find Work Container -->
                <AppDropdown class="flex items-center justify-end h-full p-3" v-if="isPermissionForAgency('find-job')">
                  <template slot="toggler">
                    <div class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      <a href="javascript:void(0);" class="text-muted text-decoration-none">Find Work</a>
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/agency/search').catch(()=>{})" class="menu">Find Jobs</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/saved-jobs').catch(()=>{})" v-if="isPermissionForAgency('find-job')">Saved Jobs</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/agency/my-proposals').catch(()=>{})" v-if="isPermissionForAgency('find-job')">Proposals</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/agency-profile').catch(()=>{})" class="menu" v-if="isPermissionForAgency('find-job')">Agency Profile</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/agency/roster').catch(()=>{})" class="menu" v-if="isPermissionForAgency('find-job')">Agency Roster</a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>

                <!-- My Job Container -->
                <AppDropdown class="flex items-center justify-end h-full p-3" v-if="(isPermissionForAgency('all-Contracts'))">
                  <template slot="toggler">
                    <div 
                      class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      My Jobs
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">

                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/agency/all-contracts').catch(()=>{})">All
                        Contracts</a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>

                <!-- Report Container -->
                <AppDropdown class="flex items-center justify-end h-full p-3" v-if="isPermissionForAgency('transactionHistory')">
                  <template slot="toggler">
                    <div 
                      class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      Reports
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/agency/over-view').catch(()=>{})" class="menu">OverView</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/agency/trasaction-history').catch(()=>{})">Transaction History</a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>
              </template>
              <!-- this is client menu bar -->
              <template v-if="$store.state.accountType == 'client'">
                <AppDropdown class="flex items-center justify-end h-full p-3" v-if="isPermissionForClient('my-jobs') || isPermissionForClient('all-contract') || isPermissionForClient('all-job-post') || isPermissionForClient('post-job')">
                  <template slot="toggler">
                    <div
                      class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted">
                      <a href="javascript:void(0);" class="text-muted text-decoration-none">Jobs</a>
                    </div>
                  </template>
                  <AppDropdownContent class="" dropdownTop="top-10" style="height: 505px">
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600" v-if="isPermissionForClient('all-contract')">
                      <a href="javascript:void(0);" class="menu" @click="$router.push('/client/all-contracts').catch(()=>{})">All Contracts</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600">
                      <a href="javascript:void(0);" @click="$router.push('/client/dashboard').catch(()=>{})" class="menu" v-if="isPermissionForClient('my-jobs')">My Jobs</a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600" v-if="isPermissionForClient('all-job-post')">
                      <a href="javascript:void(0);" class="menu" @click="$router.push({name:'jobPosting',path:'/client/job-posting',params:{pid:'post'}}).catch(()=>{})">All Job Posts </a>
                    </AppDropdownItem>
                    <AppDropdownItem style="padding: 14px 18px" class="text-black fz-16 fw-600" v-if="isPermissionForClient('post-job')">
                      <a href="javascript:void(0);" class="menu" @click="$router.push({name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}).catch(()=>{})">Post a job </a>
                    </AppDropdownItem>
                  </AppDropdownContent>
                </AppDropdown>

                <li @click="$router.push('/client/talent').catch(()=>{})" v-if="isPermissionForClient('talent')"
                  class="p-3 transition-all duration-300 ease-in rounded-md cursor-pointer fz-16 fw-600 text-muted">
                  Talent
                </li>

                <li @click="$router.push('/client/trasaction-history').catch(()=>{})"  v-if="isPermissionForClient('transactionHistory')"
                  class="p-3 transition-all duration-300 ease-in rounded-md cursor-pointer fz-16 fw-600 text-muted">
                  Reports
                </li>
              </template>

              <!-- Message Container Client -->
              <AppDropdown class="flex items-center justify-end h-full" >
                <template slot="toggler">
                  <div @click="$router.push({ name: 'chat', path: '/chat' }).catch(()=>{})"
                    class="transiçion-all duration-300 ease-in rounded-full -pl-4 cursor-pointer hover:rounded-full d-flex align-items-center fz-16 fw-600 text-muted p-3">
                    Messages
                    <span class="badge bg-theme ml-2 py-1 px-1 MsgCount" v-if="$store.state.accountType == 'freelancer' && $store.state.listAccount.qp_user.UnreadChatRoom > 0 && $store.state.listAccount.qp_user != null">{{ $store.state.listAccount.qp_user.UnreadChatRoom }}</span>
                    <span class="badge bg-theme ml-2 py-1 px-1 MsgCount" v-else-if="$store.state.accountType == 'agency' && ($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.company.UnreadChatRoom > 0">{{ $store.state.listAccount.company.UnreadChatRoom }}</span>
                    <span class="badge bg-theme ml-2 py-1 px-1 MsgCount" v-else-if="$store.state.accountType == 'client' && ($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.user.UnreadChatRoom > 0">{{ $store.state.listAccount.user.UnreadChatRoom }}</span>
                  </div>
                </template>
              </AppDropdown>

              <!-- Newfeed -->
              <li @click="getNewsFeed" class="p-3 transition-all duration-300 ease-in rounded-md cursor-pointer hover:rounded-md fz-16 fw-600 text-muted">
                Newsfeed
              </li>
              
              <!-- Notification Container -->
              <!-- <AppDropdown class="flex items-center justify-end h-full">
                <template slot="toggler">
                  <div class="relative" @click="isNotification = !isNotification">
                    <BellIcon
                      class="p-3 text-gray-400 transition-all duration-300 ease-in rounded-full cursor-pointer w-14 h-14 hover:rounded-full hover:bg-gray-200 hover:text-primary-500" />
                    <div class="absolute w-4 h-4 text-xs text-center text-white rounded-full top-2 left-7 bg-primary-500">
                      0
                    </div>
                  </div>
                </template>
                <AppDropdownContent dropdownTop="top-10" dropdownwidth="w-96" style="height: 520px">
                  <AppDropdownItem>
                    <div class="group hover:text-primary-500">
                      <div class="flex items-center justify-between px-4 py-2 border-b border-gray-300">
                        <h6 class="font-medium text-gray-900 cursor-pointer hover:text-primary-500">
                          Setting
                        </h6>
                        <h6 class="font-medium text-gray-900 cursor-pointer hover:text-primary-500">
                          Clear all
                        </h6>
                      </div>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem style="padding: 10px 20px"
                    class="pb-0 text-sm font-medium text-gray-500 transition-all duration-500 ease-in border-b border-gray-200 rounded-md cursor-pointer hover:bg-gray-300 hover:rounded-md">
                    <span class="text-base font-bold text-gray-900 cursor-pointer">
                      Jassica William
                    </span>

                    Comment on your project.

                    <div
                      class="inline-flex items-center justify-between w-full mx-auto text-base font-medium text-gray-500 truncate">
                      <p class="text-xs font-medium text-gray-500 cursor-pointer w-96">
                        Project
                      </p>
                      <p class="text-xs font-medium text-gray-400 cursor-pointer">
                        2 min ago
                      </p>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem style="padding: 10px 20px"
                    class="pb-0 text-sm font-medium text-gray-500 transition-all duration-500 ease-in border-b border-gray-200 rounded-md cursor-pointer hover:bg-gray-300 hover:rounded-md">
                    <span class="text-base font-bold text-gray-900 cursor-pointer">
                      Jassica William
                    </span>

                    Comment on your project.

                    <div
                      class="inline-flex items-center justify-between w-full mx-auto text-base font-medium text-gray-500 truncate">
                      <p class="text-xs font-medium text-gray-500 cursor-pointer w-96">
                        Project
                      </p>
                      <p class="text-xs font-medium text-gray-400 cursor-pointer">
                        2 min ago
                      </p>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem style="padding: 10px 20px"
                    class="pb-0 text-sm font-medium text-gray-500 transition-all duration-500 ease-in border-b border-gray-200 rounded-md cursor-pointer hover:bg-gray-300 hover:rounded-md">
                    <span class="text-base font-bold text-gray-900 cursor-pointer">
                      Jassica William
                    </span>

                    Comment on your project.

                    <div
                      class="inline-flex items-center justify-between w-full mx-auto text-base font-medium text-gray-500 truncate">
                      <p class="text-xs font-medium text-gray-500 cursor-pointer w-96">
                        Project
                      </p>
                      <p class="text-xs font-medium text-gray-400 cursor-pointer">
                        2 min ago
                      </p>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem style="padding: 10px 20px"
                    class="pb-0 text-sm font-medium text-gray-500 transition-all duration-500 ease-in border-b border-gray-200 rounded-md cursor-pointer hover:bg-gray-300 hover:rounded-md">
                    <span class="text-base font-bold text-gray-900 cursor-pointer">
                      Jassica William
                    </span>

                    Comment on your project.

                    <div
                      class="inline-flex items-center justify-between w-full mx-auto text-base font-medium text-gray-500 truncate">
                      <p class="text-xs font-medium text-gray-500 cursor-pointer w-96">
                        Project
                      </p>
                      <p class="text-xs font-medium text-gray-400 cursor-pointer">
                        2 min ago
                      </p>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem style="padding: 10px 20px"
                    class="pb-0 text-sm font-medium text-gray-500 transition-all duration-500 ease-in border-b border-gray-200 rounded-md cursor-pointer hover:bg-gray-300 hover:rounded-md">
                    <span class="text-base font-bold text-gray-900 cursor-pointer">
                      Jassica William
                    </span>

                    Comment on your project.

                    <div
                      class="inline-flex items-center justify-between w-full mx-auto text-base font-medium text-gray-500 truncate">
                      <p class="text-xs font-medium text-gray-500 cursor-pointer w-96">
                        Project
                      </p>
                      <p class="text-xs font-medium text-gray-400 cursor-pointer">
                        2 min ago
                      </p>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem style="padding: 10px 20px"
                    class="pb-0 text-sm font-medium text-gray-500 transition-all duration-500 ease-in border-b border-gray-200 rounded-md cursor-pointer hover:bg-gray-300 hover:rounded-md">
                    <span class="text-base font-bold text-gray-900 cursor-pointer">
                      Jassica William
                    </span>

                    Comment on your project.

                    <div
                      class="inline-flex items-center justify-between w-full mx-auto text-base font-medium text-gray-500 truncate">
                      <p class="text-xs font-medium text-gray-500 cursor-pointer w-96">
                        Project
                      </p>
                      <p class="text-xs font-medium text-gray-400 cursor-pointer">
                        2 min ago
                      </p>
                    </div>
                  </AppDropdownItem>
                  <AppDropdownItem class="absolute bottom-0 left-0 right-0" style="margin-top: px">
                    <button
                      class="w-full px-4 py-3 text-base text-white rounded-md shadow-sm cursor-pointer bg-primary-500 hover:bg-red-400">
                      View all Notification
                    </button>
                  </AppDropdownItem>
                </AppDropdownContent>
              </AppDropdown> -->
            </ul>
          </div>
        </div>

        <div v-if="!windowResolution" class="flex items-center justify-end px-2 py-6 space-x-6 lg:border-r lg:border-l md:border-gray-200 md:px-4 xl:px-7 lg:space-x-0 lg:justify-center">
          <!-- User Profile  Dropdown-->
          <div class="w-full">
            <AppDropdown>
              <template slot="toggler">
                <v-avatar class="hidden shadow-md cursor-pointer sm:hidden md:block lg:block w-14 h-14">
                  <img v-if="$store.state.currentUserData.profile_picture_path"
                    :src="$store.state.currentUserData.profile_picture_path"
                  />
                </v-avatar>
              </template>

              <transition name="account-dropdown">
                <AppDropdownContent dropdownwidth="w-96" class="z-10">
                  <div class="user-status d-flex align-items-center p-2">
                    <b-button type="button" class="w-50 mr-1" :class="(getUserOnlinestatus == 1)?'btn-theme':'btn-outline-theme'" @click="(getUserOnlinestatus == 2 && changeonlineStatusbtn == true)?changeOnlineStatus(1):''">
                      Online
                    </b-button>
                    <b-button type="button" class="w-50" :class="(getUserOnlinestatus == 2)?'btn-theme':'btn-outline-theme'" @click="(getUserOnlinestatus == 1 && changeonlineStatusbtn == true)?changeOnlineStatus(2):''">
                      Invisible
                    </b-button>
                  </div>
                  <AppDropdownItem class="flex items-center px-4 py-2 border-gray-200"
                    :class="[($store.state.accountType == 'freelancer' && $store.state.currentUserData.uuid == $store.state.listAccount.qp_user.uuid) ? 'active-profile' : 'active2']"
                    v-if="$store.state.listAccount.qp_user">
                    <a @click="(changeAccountAllow)?getfreelancerProfile($store.state.listAccount.qp_user):''" class="flex items-center">
                      <div class="shadow-lg w-14 h-14">
                        <img
                          :src="$store.state.listAccount.qp_user.profile_picture_path"
                          class="rounded-md shadow w-14 h-14" alt="" @error="userDefault" />
                      </div>
                      <div class="ml-3 cursor-pointer">
                        <h3 class="mb-0 text-black fz-16 fw-600">
                          {{ $store.state.listAccount.qp_user.first_name }} {{ $store.state.listAccount.qp_user.last_name }}
                        </h3>
                        <p style="padding: 0" class="mb-0 fz-14 fw-400 text-muted">Freelancer</p>
                      </div>
                      <div class="ml-auto" v-if="$store.state.listAccount.qp_user.UnreadChatRoom > 0">
                        <span class="badge badge-theme">{{$store.state.listAccount.qp_user.UnreadChatRoom}}</span>
                      </div>
                    </a>
                  </AppDropdownItem>

                  <AppDropdownItem class="flex items-center px-4 py-2 border-gray-200"
                    :class="[($store.state.accountType == 'agency' && $store.state.currentUserData.company_settings.company_details_id == $store.state.listAccount.company.company_details_id) ? 'active-profile' : 'active2']"
                    v-if="$store.state.listAccount.company"><a
                      @click="(changeAccountAllow)?getAgentProfile($store.state.listAccount.company):''" class="flex items-center">
                      <div class="shadow-lg w-14 h-14">
                        <img
                          :src="$store.state.listAccount.company.profile_picture_path"
                          class="rounded-md w-14 h-14 no-shadow" alt="" @error="companyDefault" />
                      </div>

                      <div class="ml-3 cursor-pointer">
                        <h3 class="mb-0 text-black fz-16 fw-600">
                          {{ $store.state.listAccount.company.company_name }}
                        </h3>
                        <p class="mb-0 fz-14 fw-400 text-muted">Agency</p>
                      </div>
                      <div class="ml-auto" v-if="($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.company.UnreadChatRoom > 0">
                        <span class="badge badge-theme">{{$store.state.listAccount.company.UnreadChatRoom}}</span>
                      </div>
                    </a>
                  </AppDropdownItem>

                  <AppDropdownItem class="flex items-center px-4 py-2 border-gray-200"
                    :class="[($store.state.accountType == 'client' && $store.state.currentUserData.uuid == $store.state.listAccount.user.uuid) ? 'active-profile' : 'active2']"
                    v-if="$store.state.listAccount.user">
                    <a @click="(changeAccountAllow)?getClientProfile($store.state.listAccount.user):''" class="flex items-center">
                      <div class="shadow-lg w-14 h-14">
                        <img
                          :src="$store.state.listAccount.user.profile_picture_path"
                          class="rounded-md w-14 h-14 no-shadow" alt="" @error="userDefault" />
                      </div>

                      <div class="ml-3 cursor-pointer">
                        <h3 class="mb-0 text-black fz-16 fw-600">
                          {{ $store.state.listAccount.user.first_name }} {{ $store.state.listAccount.user.last_name }}
                        </h3>
                        <p class="mb-0 fz-16 fw-400 text-muted">Client</p>
                      </div>
                      <div class="ml-auto" v-if="($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.user.UnreadChatRoom > 0">
                        <span class="badge badge-theme">{{$store.state.listAccount.user.UnreadChatRoom}}</span>
                      </div>
                    </a>
                  </AppDropdownItem>
                  <!-- Invited User -->

                  <AppDropdownItem class="flex items-center px-4 py-2 border-gray-200"
                    v-for="(user, index) in $store.state.listAccount.membersPermissionList" :key="'sidebar' + index"
                    :class="[$store.state.listAccount.membersPermissionList[index].uuid == $store.state.currentUserData.uuid ? 'active-profile' : 'active2']">
                    <a @click="(changeAccountAllow)?invitedMemberSwitch(user, index):''" class="flex items-center">
                      <div class="shadow-lg w-14 h-14">
                        <img :src="user.profile_picture_path"
                          class="rounded-md shadow w-14 h-14" alt="" @error="userDefault" />
                      </div>

                      <div class="ml-3 cursor-pointer">
                        <h3 class="mb-0 text-black fz-16 fw-600">
                          {{ user.first_name }} {{
                            user.last_name }}
                        </h3>
                        <p style="padding: 0" class="mb-0 fz-14 fw-400 text-muted">
                          {{ user.profileType == 1 ? 'Agency' : 'Client' }}
                        </p>
                      </div>
                    </a>
                  </AppDropdownItem>

                  <!-- Setting -->
                  <AppDropdownItem style="padding: 10px 22px"
                    class="flex items-center font-semibold text-gray-900 transition-all duration-500 ease-in-out cursor-pointer sm group hover:text-primary-500 hover:bg-gray-100">
                    <span class="flex items-center" @click="$router.push('/settings?current=0').catch(()=>{})">
                      <CogIcon class="w-5 h-5 mr-3 text-theme" />
                      Settings
                    </span>

                  </AppDropdownItem>
                  <!-- Logout -->
                  <AppDropdownItem style="padding: 10px 22px"
                    class="flex items-center font-semibold text-gray-900 transition-all duration-500 ease-in-out cursor-pointer sm group hover:text-primary-500 hover:bg-gray-100">
                    <span @click="logout" class="flex items-center">
                      <PoweroffIcon class="w-4 h-4 mr-4 text-gray-500 group-hover:text-primary-500" />
                      Log Out
                    </span>

                  </AppDropdownItem>
                </AppDropdownContent>
              </transition>
            </AppDropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Sidebar Menu-->
    <div class="block xl:hidden sm:block lg:hidden">
      <b-sidebar body-class="p-0" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" @change="updateHtmlClass()"
        no-header :backdrop-variant="'variant'" backdrop shadow>
        <template #default="{ hide }">
          <div class="">
            <!-- <div class="absolute cursor-pointer right-4 top-4" block @click="hide"
              style="padding: 8px;background-color: #000;border-radius: 12px;    top: 0.5rem;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff"
                class="bi bi-x icon-wrapper-header" viewBox="0 0 16 16">
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div> -->
            <nav>
              <!-- Online/Offline -->
              <div class="user-status d-flex align-items-center p-2">
                <b-button type="button" class="w-50 mr-1 " :class="(getUserOnlinestatus == 1)?'btn-theme':'btn-outline-theme'" @click="hide;(getUserOnlinestatus == 2 && changeonlineStatusbtn == true) ? changeOnlineStatus(1):''">
                  Online
                </b-button>
                <b-button type="button" class="w-50 " :class="(getUserOnlinestatus == 2) ? 'btn-theme' : 'btn-outline-theme'" @click="hide;(getUserOnlinestatus == 1 && changeonlineStatusbtn == true) ? changeOnlineStatus(2):''">
                  Invisible
                </b-button>
              </div>
              <ul class="list-none mt-14 mb-0" v-if="isAuth" style="padding: 0;margin-left:-32px;">
                <li class="mobile-profile-ui text-sm font-bold text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900"  v-if="windowResolution">
                  <div class="d-flex align-items-center"  @click="accountToggle = !accountToggle">
                    <div class="image-content shadow">
                      <img v-if="$store.state.currentUserData.profile_picture_path" :src="$store.state.currentUserData.profile_picture_path" class="img=fluid">
                    </div>
                    <div class="content-holder d-flex align-items-center">
                      <div>
                        <h6 class="mb-0">{{($store.state.accountType == 'freelancer')?$store.state.currentUserData.first_name+' '+$store.state.listAccount.qp_user.last_name:($store.state.accountType == 'agency')?$store.state.currentUserData.first_name:$store.state.currentUserData.first_name}}</h6>
                        <p class="mb-0">{{$store.state.accountType}}</p>
                      </div>
                      <div class="ml-2">
                        <span class="badge badge-theme" v-if="$store.state.accountType == 'freelancer' && $store.state.listAccount.qp_user.UnreadChatRoom > 0">{{ $store.state.listAccount.qp_user.UnreadChatRoom }}</span>
                        <span class="badge badge-theme" v-else-if="$store.state.accountType == 'agency' && ($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.company.UnreadChatRoom > 0">{{ $store.state.listAccount.company.UnreadChatRoom }}</span>
                        <span class="badge badge-theme" v-else-if="$store.state.accountType == 'client' && ($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.user.UnreadChatRoom > 0">{{ $store.state.listAccount.user.UnreadChatRoom }}</span>
                      </div>
                    </div>
                    <div class="ml-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-compact-down" viewBox="0 0 16 16" v-if="!accountToggle">
                        <path fill-rule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"/>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-compact-up" viewBox="0 0 16 16" v-else>
                        <path fill-rule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894z"/>
                      </svg>
                    </div>
                  </div>
                  <ul class="dropdown" style="margin-left:-10px;" v-if="accountToggle">
                    <li class="text-sm font-medium text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900 mt-3"  :class="[($store.state.accountType == 'freelancer' && $store.state.currentUserData.uuid == $store.state.listAccount.qp_user.uuid) ? 'd-none' : '']" v-if="$store.state.listAccount.qp_user" >
                      <div class="d-flex align-items-center" @click="(changeAccountAllow)?getfreelancerProfile($store.state.listAccount.qp_user):''">
                        <div class="image-content shadow">
                          <img :src="$store.state.listAccount.qp_user.profile_picture_path" class="img=fluid" @error="userDefault">
                        </div>
                        <div class="content-holder">
                          <h6 class="mb-0">{{ $store.state.listAccount.qp_user.first_name }} {{ $store.state.listAccount.qp_user.last_name }}</h6>
                          <p class="mb-0">Freelancer</p>
                        </div>
                        <div class="ml-auto" v-if="$store.state.listAccount.qp_user.UnreadChatRoom > 0">
                          <span class="badge badge-theme">{{$store.state.listAccount.qp_user.UnreadChatRoom}}</span>
                        </div>
                      </div>
                    </li>
                    
                    <li class="text-sm font-medium text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900 mt-3"  :class="[($store.state.accountType == 'agency' && $store.state.currentUserData.company_settings.company_details_id == $store.state.listAccount.company.company_details_id) ? 'd-none' : '']" v-if="$store.state.listAccount.company">
                      <div class="d-flex align-items-center" @click="(changeAccountAllow)?getAgentProfile($store.state.listAccount.company):''">
                        <div class="image-content shadow">
                          <img :src="$store.state.listAccount.company.profile_picture_path" class="img=fluid" @error="companyDefault">
                        </div>
                        <div class="content-holder">
                          <h6 class="mb-0">{{ $store.state.listAccount.company.company_name }}</h6>
                          <p class="mb-0">Agency</p>
                        </div>
                        <div class="ml-auto" v-if="($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.company.UnreadChatRoom > 0">
                          <span class="badge badge-theme">{{$store.state.listAccount.company.UnreadChatRoom}}</span>
                        </div>
                      </div>
                    </li>
                    
                    <li class="text-sm font-medium text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900 mt-3" :class="[($store.state.accountType == 'client' && $store.state.currentUserData.uuid == $store.state.listAccount.user.uuid) ? 'd-none' : '']" v-if="$store.state.listAccount.user">
                      <div class="d-flex align-items-center" @click="(changeAccountAllow)?getClientProfile($store.state.listAccount.user):''">
                        <div class="image-content shadow">
                          <img :src="$store.state.listAccount.user.profile_picture_path" class="img=fluid" @error="userDefault">
                        </div>
                        <div class="content-holder">
                          <h6 class="mb-0">{{ $store.state.listAccount.user.first_name }} {{ $store.state.listAccount.user.last_name }}</h6>
                          <p class="mb-0">Client</p>
                        </div>
                        <div class="ml-auto" v-if="($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.user.UnreadChatRoom > 0">
                          <span class="badge badge-theme">{{$store.state.listAccount.user.UnreadChatRoom}}</span>
                        </div>
                      </div>
                    </li>
                    
                    <template v-if="$store.state.listAccount.membersPermissionList.length > 0">
                      <li class="text-sm font-medium text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900 mt-3" v-for="(user, index) in $store.state.listAccount.membersPermissionList" :key="'sidebar' + index" :class="[$store.state.listAccount.membersPermissionList[index].uuid == $store.state.currentUserData.uuid ? 'd-none' : '']">
                        <div class="d-flex align-items-center" @click="(changeAccountAllow)?invitedMemberSwitch(user, index):''">
                          <div class="image-content shadow">
                            <img :src="user.profile_picture_path" class="img=fluid" @error="userDefault">
                          </div>
                          <div class="content-holder">
                            <h6 class="mb-0">{{ user.first_name }} {{
                              user.last_name }}</h6>
                            <p class="mb-0">{{ user.profileType == 1 ? 'Agency' : 'Client' }}</p>
                          </div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </li>
                <template v-for="(item, index) in menuItems">
                  <template v-if="item.type == $store.state.accountType || item.type == ''">
                    <li :key="index" v-if="checkTabPermission(item)" style="padding: 18px;border-bottom: 1px solid #eee;" class="text-sm font-bold text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900" @click="(item.label == 'Logout') ? logout() :(item.submenu.length > 0) ? toggleDropdown(item):$router.push(item.url).catch(()=>{})">
                      <a v-if="item.label == 'Messages'" >{{ item.label }}
                        <span class="badge bg-theme ml-2 py-1 px-1 MsgCount" v-if="$store.state.accountType == 'freelancer' && $store.state.listAccount.qp_user.UnreadChatRoom > 0">{{ $store.state.listAccount.qp_user.UnreadChatRoom }}</span>
                        <span class="badge bg-theme ml-2 py-1 px-1 MsgCount" v-else-if="$store.state.accountType == 'agency' && ($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.company.UnreadChatRoom > 0">{{ $store.state.listAccount.company.UnreadChatRoom }}</span>
                        <span class="badge bg-theme ml-2 py-1 px-1 MsgCount" v-else-if="$store.state.accountType == 'client' && ($store.state.currentUserData.permission == '' || $store.state.currentUserData.permission == null) && $store.state.listAccount.user.UnreadChatRoom > 0">{{ $store.state.listAccount.user.UnreadChatRoom }}</span>
                      </a>
                      <a v-else>{{ item.label }}</a>
                      <ul v-if="item.isDropdownOpen && item.submenu.length > 0" class="dropdown" style="margin-left:-10px;" >
                        <li v-for="(subItem, subIndex) in item.submenu" :key="subIndex" style="padding: 8px;list-style:disc;" class="text-sm font-medium text-gray-900 cursor-pointer hover:bg-gray-100 hover:text-gray-900" @click="$router.push(subItem.url).catch(()=>{})">
                          <a>{{ subItem.label }}</a>
                        </li>
                      </ul>
                    </li>
                  </template>
                </template>
                <li class="text-center" @click="hide" >
                  <i class="fa fa-close"></i>
                </li>
              </ul>
            </nav>
          </div>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>
<script>
import {
  // BellIcon,
  MenuAlt1Icon,
  CogIcon,
} from "@vue-hero-icons/solid";
import PoweroffIcon from "@/components/icons/PoweroffIcon.vue";
import AppDropdown from "@/components/base/AppDropdown.vue";
import AppDropdownContent from "@/components/base/AddDropdownContent.vue";
import AppDropdownItem from "@/components/base/AppDropdownItem.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AppDropdown,
    AppDropdownContent,
    AppDropdownItem,
    MenuAlt1Icon,
    PoweroffIcon,
    // BellIcon,
    //XIcon,
    CogIcon,
  },
  watch: {
    // this fun is for to rdirect to pages
    redirectPage(){
      if(this.redirectPage == 1){
        this.$toastr.s("Profile Switched successfully");
        if(this.$store.state.accountType == 'freelancer'){
          window.location.href = '/freelancer';
        }else if(this.$store.state.accountType == 'agency'){
          window.location.href = '/agency/search';
        }else if(this.$store.state.accountType == 'client'){
          window.location.href = '/client/dashboard';
        }
      }
    }
  },
  data() {
    return {
      isOpen                : false,
      isAccount             : false,
      // isMessage             : false,
      isNotification        : false,
      isAuth                : null,
      searchValue           : '',
      changeAccountAllow    : true,
      redirectPage          : 0,
      changeonlineStatusbtn : true,
      accountToggle       : false,

      menuItems           : [
        {
          type : 'freelancer',
          label: 'Find Work',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'Find Jobs' ,url :'/freelancer/search'},
            { label: 'Saved Jobs' ,url :'/saved-jobs'},
            { label: 'Proposals' ,url :'/freelancer/my-proposals'},
            { label: 'Profile' ,url :'/my-profile'},
          ],
        },
        {
          type : 'freelancer',
          label: 'My Jobs',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'My Jobs' ,url :'/freelancer/my-jobs'},
            { label: 'All Contracts' ,url :'/freelancer/all-contracts'},
          ],
        },
        {
          type : 'freelancer',
          label: 'Reports',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'OverView' ,url :'/freelancer/over-view'},
            { label: 'Transaction History' ,url :'/freelancer/trasaction-history'},
          ],
        },
        {
          type : 'agency',
          label: 'Find Work',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'Find Jobs' ,url :'/agency/search'},
            { label: 'Saved Jobs' ,url :'/saved-jobs'},
            { label: 'Proposals' ,url :'/agency/my-proposals'},
            { label: 'Agency Profile' ,url :'/agency-profile'},
            { label: 'Agency Roster' ,url :'/agency/roster'},
          ],
        },
        {
          type : 'agency',
          label: 'My Jobs',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'All Contracts' ,url :'/agency/all-contracts'},
          ],
        },
        {
          type : 'agency',
          label: 'Reports',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'OverView' ,url :'/agency/over-view'},
            { label: 'Transaction History' ,url :'/agency/trasaction-history'},
          ],
        },
        {
          type : 'client',
          label: 'Jobs',
          url :'',
          function:'',
          isDropdownOpen: false,
          submenu: [
            { label: 'All Contracts' ,url :'/client/all-contracts'},
            { label: 'My Jobs' ,url :'/client/dashboard'},
            { label: 'All Job Posts' ,url :{name:'jobPosting',path:'/client/job-posting',params:{pid:'post'}}},
            { label: 'Post a job' ,url :{name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}},
          ],
        },
        {
          type : 'client',
          label: 'Talent',
          url: '/client/talent',
          function:'',
          isDropdownOpen: false,
          submenu: [],
        },
        {
          type : 'client',
          label: 'Reports',
          url: '/client/trasaction-history',
          function:'',
          isDropdownOpen: false,
          submenu: [],
        },
        {
          type : '',
          label: 'Messages',
          url: '/chat',
          function:'',
          isDropdownOpen: false,
          submenu: [],
        },
        {
          type : '',
          label: 'Newsfeed',
          url : '/news-feed',
          function:'',
          isDropdownOpen: false,
          submenu: [],
        },
        {
          type : '',
          label: 'Settings',
          url : '/settings?current=0',
          function:'',
          isDropdownOpen: false,
          submenu: [],
        },
        {
          type : '',
          label: 'Logout',
          url : '',
          function:'logout()',
          isDropdownOpen: false,
          submenu: [],
        },
      ],
    };
  },

  computed: {
    ...mapGetters("global", ["refreshNewsFeed"]),
    ...mapGetters("index", ["listAccount"]),
    ...mapGetters(["getUserOnlinestatus"]),
    
    ...mapGetters({windowResolution :"getmobileWindow"}),

  },

  mounted() {
    // this.$store.dispatch("accountType",localStorage.getItem("accountType")); 
    // this.listAccount = this.listAccount();
    this.isAuth = this.$store.state.currentUserData.token;
    // this.isAuth = localStorage.getItem("token");
    if (this.$route.name == "ExpireLink") {
      this.isAuth = null;
    }
    // var offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.getCompanyCount();
  },

  methods: {
     // this fun is for to check tab permission for mobile responsive
     checkTabPermission(_data,_type){
      if(_type == 'agency' && _data.label =='Find Work'){
        return this.isPermissionForAgency('find-job');
      }else if(_type == 'agency' && _data.label =='My Jobs'){
        return this.isPermissionForAgency('all-Contracts');
      }else if(_type == 'agency' && _data.label =='Reports'){
        return this.isPermissionForAgency('transactionHistory');
      }else if(_type == 'client' && _data.label =='Talent'){
        return this.isPermissionForClient('talent');
      }else if(_type == 'agency' && _data.label =='Reports'){
        return this.isPermissionForAgency('transactionHistory');
      }
      return true;
    },
    toggleDropdown(item) {
      item.isDropdownOpen = !item.isDropdownOpen;
    },
    
    ...mapActions(["genericAPIPOSTRequest","UpdateUserOnlineOfflineStatus"]),
    ...mapActions("global",["UpdateRequestRoomData","UpdateInvitationRequestRoomData","UpdateMessageRequestData","EmptyMessageData","EmptyRoomData"]),
    // this fun is for to update online status of user
    async changeOnlineStatus(_v){
      this.changeonlineStatusbtn = false;
      var userData = await this.UpdateUserOnlineOfflineStatus({params : (_v == 2) ? _v : 1});
      if(userData){
        this.changeonlineStatusbtn = true;
        this.hide;
      }
    },
    setAltImg(event) {
      event.target.src = this.$store.state.baseUrlImg + "/upload/profile_image/user.png"
    },
    companyDefault(e) {
      e.target.src = this.$store.state.baseUrlImg + "/upload/company_image/company.png";
    },
    userDefault(e) {
      e.target.src = this.$store.state.baseUrlImg + "/upload/profile_image/user.png";
    },
    

    getNewsFeed() {
      window.scrollTo(0, 0);
      if (window.location.pathname !== "/news-feed") {
        this.$router.push("/news-feed").catch(()=>{});
      } else {
        location.reload();
        // if (this.refreshNewsFeed) {
        //   this.refreshNewsFeed();
        // }
      }
    },
    getClientProfile(user) {
      this.changeAccountAllow = false;
      window.scrollTo(0, 0);
      //if (window.location.pathname !== "/client/dashboard") {
      this.$store.dispatch("accountType", 'client');
      localStorage.setItem("accountType", "client");
      this.switchaccount(2, user.uuid)
      // if (res){
      //   // this.redirectFn('client/dashboard');
      // }
      //}  
    },
    getAgentProfile(user) {
      this.changeAccountAllow = false;
      window.scrollTo(0, 0);
      localStorage.setItem("accountType", "agency");
      this.$store.dispatch("accountType", 'agency');
      this.switchaccount(1, user.uuid);
      // if (res) {
      //   // this.redirectFn('agency/search');
      // }
    },
    CloseOtherTabs(event){
      // Get the current domain
      var currentDomain = window.location.hostname;
      var targetElement = event.target;

      // Get the domain of the target element
      var targetDomain = targetElement.hostname;

      // Check if the target domain is the same as the current domain
      if (targetDomain === currentDomain) {
        // Close all tabs within the same domain
        var openTabs = window.open('', '_self');
        openTabs.close();
      }
    },
    getfreelancerProfile(user) {
      this.changeAccountAllow = false;
      window.scrollTo(0, 0);
      localStorage.setItem("accountType", "freelancer");
      this.$store.dispatch("accountType", 'freelancer');
      this.switchaccount(3, user.uuid);
      // if (res) {
      //   // this.redirectFn('freelancer');
      // }
    },
    invitedMemberSwitch(user) {
      this.changeAccountAllow = false;
      window.scrollTo(0, 0);
      var account = '';
      if (user.profileType == 1) {
        account = 'agency';
      }
      if (user.profileType == 2) {
        account = 'client';
      }
      localStorage.setItem("accountType", account);
      this.$store.dispatch("accountType", account);
      this.switchaccount(user.profileType, user.uuid);
      // if (res) {
      //   if(account == 'agency'){
      //     // this.redirectFn(account+'/search')
      //   }else{
      //     // this.redirectFn(account+'/dashboard')
      //   }
      // }
    },
    
    async switchaccount(accountType, uuid = '') {
      var res = await this.genericAPIPOSTRequest({
        requestUrl: "user/switchAccount",
        params: { account: accountType, 'uuid': uuid, data: this.getLoginUserIdRequest() },
      });
      if(res){
        localStorage.setItem(
          "token",
          "Bearer " + res.token
        );
        localStorage.setItem(
          "loginUserData",
          JSON.stringify(res)
        );
        this.$store.dispatch("currentUserData", res);
        this.updateUserOnlineStatus(res)

        this.EmptyMessageData();
        this.EmptyRoomData();
        // this.getCompanyCount();
        // this.redirectPage+=1;
      }
    },

    async updateUserOnlineStatus(_d){
      await this.UpdateUserOnlineOfflineStatus({params : (_d.online_status == 2) ? _d.online_status :1});
      this.redirectPage += 1;
    },
    
  },
};
</script> 
<style scoped>
.icon-wrapper-header {
  width: 18px;
  height: 18px;
  padding: 1px;
}

.icon-wrapper-header svg {
  width: 16px;
  height: 16px;
  color: #b0b0b0;
}

.account-dropdown-enter-active,
.account-dropdown-leave-active {
  transition: all 0.5s;
}

.account-dropdown-enter,
.account-dropdown-leave-to {
  opacity: 0;
  transform: translateY(-110px);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
  transform: translateY(20px);
}

.list-enter,
.list-leave-to .list-leave-active {
  opacity: 0;
  transform: translateY(-30px);
}

.navbar-main {
  height: 80px;
  z-index: 1111;
}

@media only screen and (max-width: 1165px) {
  .navbar-main {
    height: 80px;
  }
}

.menu-items-list {
  /*padding-right: 250px;*/
  padding-right: 0px;
  margin-left: 10px;
}

@media only screen and (max-width: 1870px) {
  .menu-items-list {
    padding-right: 250px;
  }
}

@media only screen and (max-width: 1400px) {
  .menu-items-list {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1450px) {
  .menu-items-list {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1112px) {
  .menu-items-list {
    padding-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1269px) {
  .toggle-switch {
    display: none;
  }
}


.text-muted {
  color: #999999 !important;
}

.text-grey {
  color: #979797 !important;
}

.fz-14 {
  font-size: 14px;
}

.fz-30 {
  font-size: 26px !important;
}

.fz-16 {
  font-size: 16px;
}

fw-600 {
  font-weight: 600;
}

.w-14 {
  width: 3rem;
}

.h-14 {
  height: 3rem;
}

.badge-theme {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  color: #ffffff !important;
  background: #f2613c;
  border-radius: 10px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.menu {
  text-decoration: none !important;
  color: #000;
}

.menu:hover {
  color: #f2613c;
}

.active-profile {
  background-color: #FFF6F4 !important;
}
</style>