<template>
    <b-container>
        <div v-infinite-scroll="getoverView"
        :infinite-scroll-disabled="busy"
        infinite-scroll-distance="5">
        <b-row class="mt-100 mb-50 overview-wrap  freelancer-wrap contracts-wrap">
            <b-col cols="12">
            <h1 class="mb-2 fw-500 text-black"><b>Overview</b></h1>
            </b-col>
    
            <b-col cols="12">
            <b-card-group deck>
                <b-card no-body class="py-4 px-4 bg-white overview-pills">
                <ul class="nav nav-pills mb-5 minus--30 pills-justified" justified id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation" @click="getData('progress')">
                        <button class="nav-link text-left" :class="(currentUrl=='progress')?'active':''" id="pills-progress-tab" data-bs-toggle="pill" data-bs-target="#pills-progress" type="button" role="tab" aria-controls="pills-progress" >
                            <div class="d-flex align-items-center"> 
                            Work in progress 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle ml-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                            </svg>
                            </div>
                            <span class="text-black fz-22">${{sumData.progress}}</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" @click="getData('review')">
                        <button class="nav-link text-left" :class="(currentUrl=='review')?'active':''" id="pills-review-tab" data-bs-toggle="pill" data-bs-target="#pills-review" type="button" role="tab" aria-controls="pills-review"> 
                            <div class="d-flex align-items-center"> 
                            In review 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle ml-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                            </svg>
                            </div>
                            <span class="text-black fz-22">${{sumData.review}}</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" @click="getData('pending')">
                        <button class="nav-link text-left" :class="(currentUrl=='pending')?'active':''" id="pills-pending-tab" data-bs-toggle="pill" data-bs-target="#pills-pending" type="button" role="tab" aria-controls="pills-pending"> 
                            <div class="d-flex align-items-center"> 
                            Pending 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle ml-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                            </svg>
                            </div>
                            <span class="text-black fz-22">${{sumData.pending}}</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" @click="getData('available')">
                        <button class="nav-link text-left" :class="(currentUrl=='available')?'active':''" id="pills-available-tab" data-bs-toggle="pill" data-bs-target="#pills-available" type="button" role="tab" aria-controls="pills-available"> 
                            <div class="d-flex align-items-center"> 
                            Available 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle ml-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                            </svg>
                            </div>
                            <span class="text-black fz-22">${{sumData.available}}</span>
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade " :class="(currentUrl=='progress')?'show active':''" id="pills-progress" role="tabpanel" aria-labelledby="pills-progress-tab">
                        <div class="tab-content-wrapper">
                            <h4>Fixed price milestone | <span>in progress</span></h4>
                            <div class="table-responsive w-100">
                            <table class="w-100 table-bordered">
                                <thead>
                                    <tr>
                                        <th class="width-20">Assigned</th>
                                        <th class="width-60">Job/ Milestone</th>
                                        <th class="width-20">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="responseData.length > 0">
                                        <tr v-for="(data,index) in responseData" :key="index">
                                            <td>{{data.date}}</td>
                                            <td><span class="text-theme">{{data.milestone}}</span></td>
                                            <td>{{data.amount_$}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"></td>
                                            <td class="text-left">{{progressTotal}}</td>
                                        </tr>
                                    </template>
                                    <tr v-else >
                                        <td colspan="3" class="text-center">You have no work in progress</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :class="(currentUrl=='review')?'show active':''" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab">
                        <div class="tab-content-wrapper">
                            <h4>Fixed price milestone | <span>in review</span></h4>
                            <div class="table-responsive w-100">
                            <table class="w-100 table-bordered">
                                <thead>
                                    <tr>
                                        <th>Submitted</th>
                                        <th>Job/ Milestone</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="responseData.length > 0">
                                        <tr v-for="(data,index) in responseData" :key="index">
                                            <td>{{data.date}}</td>
                                            <td><span class="text-theme">{{data.milestone}}</span></td>
                                            <td>{{data.amount_$}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"></td>
                                            <td class="text-left">{{reviewTotal}}</td>
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td colspan="3" class="text-center">You have no work in review</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :class="(currentUrl=='pending')?'show active':''" id="pills-pending" role="tabpanel" aria-labelledby="pills-pending-tab">
                        <div class="tab-content-wrapper">
                            <h4>Fixed price milestone | <span>in approved</span></h4>
                            <div class="table-responsive w-100">
                            <table class="w-100 table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Job/ Milestone</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="responseData.length > 0">
                                        <tr v-for="(data,index) in responseData" :key="index">
                                            <td>{{data.date}}</td>
                                            <td><span class="text-theme">{{data.milestone}}</span></td>
                                            <td>{{data.amount_$}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"></td>
                                            <td class="text-left">{{pendingTotal}}</td>
                                        </tr>
                                    </template>
                                    <tr v-else >
                                        <td colspan="3" class="text-center">You have no work in pending</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        <div class="tab-content-wrapper">
                            <h4>Other payments and adjustments</h4>
                            <div class="table-responsive w-100">
                            <table class="w-100 table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="otherData.length > 0">
                                        <tr v-for="(data,index) in otherData" :key="index">
                                            <td>{{data.date}}</td>
                                            <td><span class="text-theme">{{data.milestone}}</span></td>
                                            <td>{{data.amount_$}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"></td>
                                            <td class="text-left">{{otherAmountTotal}}</td>
                                        </tr>
                                    </template>
                                    <tr v-else >
                                        <td colspan="3" class="text-center">You have no work in pending</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :class=" (currentUrl=='available') ? 'show active' : ''" id="pills-available" role="tabpanel" aria-labelledby="pills-available-tab">
                        <div class="tab-content-wrapper">
                            <h4>Recent Transaction </h4>
                            <div class="table-responsive w-100">
                            <table class="w-100 table-bordered">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <!-- <th>Balance</th> -->
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-if="responseData.length > 0">
                                        <tr v-for="(data,index) in responseData" :key="index">
                                            <td>{{data.date}}</td>
                                            <td>{{data.type}}</td>
                                            <td><span class="text-theme">{{data.milestone}}</span></td>
                                            <td>{{data.amount_$}}</td>
                                            <!-- <td>{{data.balance}}</td> -->
                                        </tr>
                                    </template>
                                    <tr v-else >
                                        <td colspan="4" class="text-center">You have no work in review</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
                </b-card>
            </b-card-group>
            </b-col>
        </b-row>
        </div>
    </b-container>
  </template>
  <script>
    import { mapActions } from "vuex";
    export default {
      components: {
      },
      computed: {
        progressTotal(){
            var price = 0;
            if(this.responseData.length > 0){
                this.responseData.map(function(data){
                   price  += parseFloat(data.amount);
                })
            }
            return '$'+price;
        },
        reviewTotal(){
            var price = 0;
            if(this.responseData.length > 0){
                this.responseData.map(function(data){
                   price  += parseFloat(data.amount);
                })
            }
            return '$'+price;
        },
        pendingTotal(){
            var price = 0;
            if(this.responseData.length > 0){
                this.responseData.map(function(data){
                   price  += parseFloat(data.amount);
                })
            }
            return '$'+price;
        },
        otherAmountTotal(){
            var price = 0;
            if(this.otherData.length > 0){
                this.otherData.map(function(data){
                   price  += parseFloat(data.amount);
                })
            }
            return '$'+price;
        }
      },
      data() {
        return {
          currentUrl              : 'progress',
          responseData            : [],
          otherData               : [],
          sumData                 : [],
          busy                    : false,
          apiStatus               : false,
          loaderStatus            : false,
          filtersValue            : {
            start_limit             : 0,
            end_limit               : 5,
            data                    : this.getLoginUserIdRequest(),
            type                    : this.$store.state.accountType,
            dataType                : 'progress',
          },
        }
      },
      methods: {
        ...mapActions(['genericAPIPOSTRequest']),
        // this fun is for to get data on change of filter
        getData(_v){
            var new_url = "?current="+_v+"";
            window.history.pushState('data', 'title', new_url);
            this.getoverViewcalculation();
            this.responseData= [];
            this.otherData = [];
            this.filtersValue.start_limit = 0;
            this.filtersValue.dataType = _v;
            this.busy = false;
            this.getoverView();  
        },
        // this fun for over view section data
        async getoverView(){
          if(this.busy)return true;
          if(this.apiStatus)return true;
          this.busy                   = true;
          this.apiStatus              = true;
          this.loaderStatus           = true;
          var res                     = await this.genericAPIPOSTRequest({
              requestUrl            : "AgencyOverView",
              params                : this.filtersValue,
          });  
          if(res!=""){
            var that                = this;
            if(this.filtersValue.dataType != 'pending'){
                res.response.map(function(item){ return  that.responseData.push(item)  } );
            }else{
                res.response.response_one.map(function(item){ return  that.responseData.push(item)  } );                
                res.response.response_two.map(function(item){ return  that.otherData.push(item)  } );                
            }
            this.filtersValue.start_limit = res.start;
            this.busy               = false;
            this.apiStatus          = false;
          }else{
            this.apiStatus          = false;
          }
          this.loaderStatus         = false;
        },
        // this fun for get overview amounts calculation
        async getoverViewcalculation(){
          var res                     = await this.genericAPIPOSTRequest({
              requestUrl            : "overViewcalculation",
              params                : {
                'type'                  : this.$store.state.accountType,
              },
          }); 
          this.sumData = res; 
        },
      },
      mounted(){
        this.currentUrl = (this.$route.query.current != '' && this.$route.query.current != undefined) ? this.$route.query.current : 'progress';
        this.getData(this.currentUrl);
        window.scrollTo(0, 0);
        if(this.isPermissionForAgency('transactionHistory')!= true){
          history.go(-1)
        }
      }
    }
  </script>



