import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import "vue-wysiwyg/dist/vueWysiwyg.css";
// Router
import router from './router'
// Store action
import store from './store'
// Main style
import './assets/index.css'
// Vuetify
import vuetify from './plugin/vuetify'
// import Vuetify from 'vuetify/lib'
// Placeholder content
import VueContentPlaceholders from 'vue-content-placeholders'
// Vue Infinite scroll
var infiniteScroll = require('vue-infinite-scroll')

// Aos Animation
import AOS from 'aos'
import 'aos/dist/aos.css'
import wysiwyg from "vue-wysiwyg";
// Toastr
import vueToastr from 'vue-toastr'
// Swal Alert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
// Bootstrap Vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vuelidate validation
import Vuelidate from 'vuelidate'
import { EmojiPickerPlugin } from 'vue-emoji-picker'
import VueTinySlider from "vue-tiny-slider";
import "tiny-slider/src/tiny-slider.scss";
import commonmixins from './mixins/commonutils';
import Confirm  from './components/modal/Confirm.vue';
import ReadMore from 'vue-read-more';
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
// import { StripePlugin } from '@vue-stripe/vue-stripe';
// const optionss = {
//   pk: 'pk_test_51JdqlfBCeXjCoMqA9kvCfL3NeJ1S3QD3ICvbmB66GwjwIvi9XafbWxFVEZqDUq0YENdHJY4OEhxM0QG5sb2HgRmi00ZPTT890M',
//   stripeAccount: 'acct_1JdqlfBCeXjCoMqA',
//   apiVersion: '2022-08-01',
//   locale: 'en',
// };
// Vue.use(StripePlugin, optionss);

// import DateRangePicker from "@gravitano/vue-date-range-picker";
// import DateRangePicker from 'vue2-daterange-picker'

// Vue.use(DateRangePicker);
 
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#f2613c',
  showClass: {
    popup: 'animate__animated animate__fadeInDown',
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutUp',
  },
}

AOS.init({
  offset: 200,
  duration: 500,
  easing: 'ease-in-sine',
  delay: 100,
})

// Vue.use(AOS);
Vue.use(ReadMore);
Vue.use(VueTelInput)
Vue.mixin(commonmixins)
Vue.use(Vuelidate)
Vue.use(vueToastr, {
  maxOpened: 1,
  defaultPosition: 'toast-bottom-right',
  preventDuplicates: true,
})
Vue.use(VueSweetalert2, options)
Vue.use(VueContentPlaceholders)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(infiniteScroll)
Vue.use(VueTextareaAutogrowDirective)
Vue.use(EmojiPickerPlugin)
Vue.config.productionTip = false
Vue.use(wysiwyg, {
  hideModules: { 
    "image": true,
    "headings":true,
    "justifyLeft":true,
    "justifyCenter":true,
    "justifyRight":true,
    "orderedList":true,
    "unorderedList":true,
    "table":true,
    "removeFormat":true,
    "separator":true,
  },
  toolbar: 'left'
});
Vue.component("tiny-slider", VueTinySlider);
Vue.component('Confirm-model',Confirm)
new Vue({
  router,
  store,
  vuetify,
  Confirm,
  render: (h) => h(App),
  created() {
    // Listen for changes in localStorage
    window.addEventListener('storage', event => {
      var token = localStorage.getItem("token");
      if (this.$store.state.accountType!== 'undefined') {
        if(!this.$store.state.accountType){
          if (this.$store.state.accountType!=='' || this.$store.state.accountType!==null || this.$store.state.accountType!=='undefined') {
            if (token=== null || token==='') {
             let urlSegments = window.location.pathname.split('/').filter(segment => segment !== '');
             if(urlSegments[0]!=='login'){
               window.location.reload();
             }
           }
         }
       }  
     }
     if (event.key === 'vuexState') {
        this.$store.replaceState(JSON.parse(event.newValue));
     }
    });
  }
}).$mount('#app')
