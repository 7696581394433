<template>
  <div
    style="background: rgba(242, 97, 60, 0.07)"
    class="w-full h-auto px-4 pt-12 pb-12 xl:px-0"
  >
    <div class="w-full mx-auto text-center xl:mx-auto xl:max-w-7xl">
      <h6 class="fw-600 tex-sm xl:text-xl">
        Work with Internationally recognized Talent from anywhere 
      </h6>

      

      <div class="logo-slider mt-5">
        <div ref="slick" class="slider">
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/aramco.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/tata.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/adani2.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/petrofac.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/samsung.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/mcdermott.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="items">
            <div class="div-img-holder">
              <img
                src="/web/agencies/zeppelin/fluor.png"
                class="transition-all duration-700 ease-in-out transform cursor-pointer img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Quality Work -->
      <div class="mt-20">
        <p class="text-xs uppercase fw-600 text-primary-500">
          most popular categories
        </p>

        <h2 class="text-xl fw-bold xl:text-4xl" style="font-weight: 800">
          Confidently hire your workforce globally 
        </h2>
        <p class="mt-2 text-xs fw-400 xl:text-sm">
          Qapin connects businesses with a network of <a href="javascript:void(0)" @click="$router.push({name:'PrivacyPolicy',path:'/privacy-policy'}).catch(()=>{})">source inspectors</a> to get <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">inspections</a> done.
        </p>
      </div>

      <!-- WEB version Categoties Agencies -->
      <div class="most-popular-wrap">
        <div
          class="grid grid-cols-1 gap-3 mt-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        >
          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 bg-green-500 rounded-md colored-box"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left text-theme-hover fw-bold xl:text-base"
            >
            Mechanical Inspectors 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #0082fa">
                2,760
              </span>
              experts
            </p>
          </div>

          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #0082fa"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Electrical Inspectors
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #0082fa">
                2,679
              </span>
              experts
            </p>
          </div>

          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #ff3c3c"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Coating Inspectors 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #ff3c3c">
                1,543
              </span>
              experts
            </p>
          </div>

          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #ffc737"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Hard Goods Inspectors 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #ffc737">
                2,276
              </span>
              experts
            </p>
          </div>
        </div>

        <div
          class="grid grid-cols-1 gap-3 mt-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        >
          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer agency-category hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #ff388c"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Safety Engineers 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #ff388c"
                >1,873</span
              >
              experts
            </p>
          </div>
          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #2ddcbc"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Civil Engineers 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #2ddcbc"
                >3,162
              </span>
              experts
            </p>
          </div>
          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border-2 border-white border-solid rounded-md cursor-pointer hover:bg-primary-500 hover:border-solid hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #ca30e3"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Sourcing Agents 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #ca30e3"
                >2,453</span
              >
              experts
            </p>
          </div>

          <div
            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl"
          >
            <div class="w-8 p-4 rounded-md colored-box" style="background: #0082fa"></div>

            <h4
              style="font-weight: 700"
              class="mt-4 text-sm text-left fw-bold xl:text-base"
            >
            Apparel Inspectors 
            </h4>
            <!-- <h5 class="text-sm text-left fw-600">Inspectors</h5> -->
            <p class="mt-2 text-left uppercase fw-400">
              <span class="mr-3 text-xs cursor-pointer" style="color: #33ceff"
                >1,765
              </span>
              experts
            </p>
          </div>
        </div>
      </div>

      

      <div class="mt-2">
        <p class="text-base fw-400">Don’t see what you looking for ?</p>
        <button
          @click="$router.push('/login').catch(()=>{})"
          class="w-full px-4 py-2 text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer fw-400 md:w-52 hover:opacity-50 bg-primary-500 hover:shadow-lg"
        >
          Show All Categoties
        </button>
      </div>
    </div>
  </div>
</template>

<script type="module">
import $ from 'jquery';
import 'slick-carousel';
export default {
  components: {},

  data() {
    return {  
      
    };
  },
  mounted() {
    // Initialize Slick Carousel once the component is mounted
    this.$nextTick(() => {
      this.slickInstance = $(this.$refs.slick).slick({
        // Slick options go here
        // For example:
        slidesToShow: 6,
        autoplay:true,
        loop: true,
        arrow:false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              dots: true,
            },
          },
        ],
      });
    });
  },
  beforeDestroy() {
    $(this.$refs.slick).slick('unslick');
  }
};
</script>

<style scoped>
.hero-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  line-height: 75px;
}

.agency-category {
  background-color: white;
}
.text-xs {
  font-size: 19px;
    line-height: 1rem;
}
.agency-category:hover {
  background-color: peru;
}
@media (min-width: 1280px){

.xl\:text-4xl {
    font-size: 36px;
    line-height: 2.5rem;
}
}
.text-base{
  font-size: 19px;
}

</style>
