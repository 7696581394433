<template>
    <b-modal id="withdrawal-request-modal" size="lg" title="Add Withdraw Request" centered no-close-on-backdrop hide-footer>
        <b-form @submit.prevent="submitWithdrawRequest">
            <b-row>
            <b-col xl="12" lg="12" sm="12">
                <b-alert show variant="danger" v-if="maxAmount > 0">Amount will be procced every Wednesday</b-alert>
                <b-alert show variant="danger" v-else-if="maxAmount == 0">Available Balance is Low</b-alert>
            </b-col>
            <b-col xl="12" lg="12" sm="12">
                <b-form-group>
                    <label>Account Details</label>
                    <b-form-select 
                        v-model="WithrawalRequest.get_paid_id"
                        :options="activeAccountList" 
                        value-field="get_paid_id"
                        text-field="account"
                        class="form-control"
                    ></b-form-select>
                    <p class="text-danger" v-if="WithrawalRequestAccountErrors[0]">{{WithrawalRequestAccountErrors[0]}}</p>
                </b-form-group>
            </b-col>
            <b-col xl="12" lg="12" sm="12">
                <b-form-group>
                <label>Amount</label>
                    <b-input-group prepend="$">
                        <b-form-input 
                            v-model="WithrawalRequest.amount"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        ></b-form-input>
                    </b-input-group>
                    <p class="text-danger pt-2" v-if="WithrawalRequestAmountErrors[0]">{{WithrawalRequestAmountErrors[0]}}</p>
                    <p class="text-danger pt-2" v-if="(WithrawalRequestAmountErrors[0] || WithrawalRequest.amount !='')&& parseFloat(WithrawalRequest.amount) > parseFloat(maxAmount) ">Amount will Not be greater then Available Amount</p>
                    <p class="text-danger pt-2" v-if="(WithrawalRequestAmountErrors[0] || parseFloat(WithrawalRequest.amount) == 0)  && WithrawalRequest.amount !=''">Amount should be greater then Zero</p>
                </b-form-group>
            </b-col>
            <b-col xl="12" lg="12" sm="12"> 
                <b-form-group class="text-right">
                    <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('withdrawal-request-modal');$emit('refreshBalance');resetModel()">Cancel</a>
                    <b-button type="submit" class="btn btn-theme">Submit</b-button>
                </b-form-group>
            </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>
<script>
   
  import { validationMixin } from 'vuelidate'; 
  import { required } from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  export default {
    mixins: [validationMixin],
    validations: {
        WithrawalRequest        : {
            get_paid_id             : {required},
            amount                  : {required},
        }
    },
    props:['activeAccountList','maxAmount'],
    data() {
      return { 
        WithrawalRequest        : {
            payment_type            : 2,
            payment_status          : 'pending',
            get_paid_id             : '',
            amount                  : '',
            request_status          : '0',

        },
        submitBtnDisabled       : false,
      };
    },
    computed: { 
        WithrawalRequestAccountErrors() {
            const errors = [];
            if (!this.$v.WithrawalRequest.get_paid_id.$dirty) return errors;
            !this.$v.WithrawalRequest.get_paid_id.required && errors.push("Account is required");
            return errors;
        },
        WithrawalRequestAmountErrors() {
            const errors = [];
            if (!this.$v.WithrawalRequest.amount.$dirty) return errors;
            !this.$v.WithrawalRequest.amount.required && errors.push("Amount is required");
            return errors;
        },
    }, 
    mounted() {
        
    },
    methods: {
      ...mapActions(["genericAPIPOSTRequest"]),
      //this fun is for to add withdraw request
      async submitWithdrawRequest(){
        this.$v.$touch();
        if(!this.$v.WithrawalRequest.$invalid && this.WithrawalRequest.amount <= this.maxAmount && this.WithrawalRequest.amount != 0){
            if(this.submitBtnDisabled != false){
                return false;
            }
            this.submitBtnDisabled =true;
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "withdrawalRequest",
                params: this.WithrawalRequest,
            }); 
            if(res){
                this.$toastr.s("Withdraw Requested successfull");
                this.submitBtnDisabled = false;
                this.$bvModal.hide('withdrawal-request-modal');
            }else{
                this.submitBtnDisabled = false;
                this.$toastr.e("Withdraw Requested unsuccessfull");
            }
            this.resetModel()
            this.$emit('refreshBalance');
        }
      },
      resetModel(){
        this.$v.$reset();
        this.WithrawalRequest        = {
            payment_type            : 2,
            payment_status          : 'pending',
            get_paid_id             : '',
            amount                  : '',
            request_status          : '0',
        }
      },
    }
  };
</script>